import {CssVarsProvider} from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel, {formLabelClasses} from '@mui/joy/FormLabel';
import {Link as RouterLink} from 'react-router-dom';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import {useNavigate} from "react-router-dom";
import { apiClient } from '../lib/api/api-client';
import { FormEvent, useEffect } from 'react';
import { Divider, Link } from '@mui/joy';

interface FormElements extends HTMLFormControlsCollection {
  username: HTMLInputElement;
  password: HTMLInputElement;
  // persistent: HTMLInputElement;
}

interface SignInFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

export default function LoginPage() {
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  
  useEffect(() => {
    if (token) {
      navigate('/console')
    }
  }, [navigate, token])

  return (
    <CssVarsProvider defaultMode="light" disableTransitionOnChange>
      <CssBaseline/>
      <GlobalStyles
        styles={{
          ':root': {
            '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
            '--Cover-width': '50vw', // must be `vw` only
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width:
            'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(12px)',
          backgroundColor: 'rgba(255 255 255 / 0.2)'
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width:
              'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
            maxWidth: '100%',
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: 'flex',
              alignItems: 'left',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{gap: 2, display: 'flex', alignItems: 'center'}}>
              <Typography level="title-lg">하고오개 파트너센터</Typography>
            </Box>
          </Box>
          <Box
            component="main"
            sx={{
              my: 'auto',
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .${formLabelClasses.asterisk}`]: {
                visibility: 'hidden',
              },
            }}
          >
            <Stack gap={1}>
              <Typography level="h3">로그인</Typography>
              <Typography level="body-sm">새로 오셨나요? (수의사 또는 수의학과 학생만 가입 가능합니다.)</Typography>

              <Link 
                component={RouterLink}
                to='/sign-up'
              >
                <Button variant='outlined' sx={{backgroundColor: 'white'}} fullWidth>
                  파트너 가입 신청
                </Button>
              </Link>
            </Stack>

            <Divider
              sx={{
                color: { xs: '#FFF', md: 'text.tertiary' },
                '--Divider-lineColor': {
                  xs: '#FFF',
                  md: 'var(--joy-palette-divider)',
                },
                
              }}
            >
              or
            </Divider>

            <Stack gap={4} sx={{mt: 1}}>
              <form
                onSubmit={async (event: FormEvent<SignInFormElement>) => {
                  event.preventDefault();
                  const formElements = event.currentTarget.elements;
                  const data = {
                    username: formElements.username.value,
                    password: formElements.password.value,
                  };

                  const resp = await apiClient().POST(
                    '/partner/auth/login',
                    {
                      body: {
                        username: data.username,
                        password: data.password
                      }
                    }
                  )

                  if (resp.error) {
                    alert((resp as any).error.message || '로그인 실패.')
                    return
                  }
                  localStorage.setItem('token', resp.data?.token)
                  navigate('/console')
                  

                }}
              >
                <FormControl required>
                  <FormLabel>아이디</FormLabel>
                  <Input type="string" name="username"/>
                </FormControl>
                <FormControl required>
                  <FormLabel>비밀번호</FormLabel>
                  <Input type="password" name="password"/>
                </FormControl>
                <Stack gap={4} sx={{mt: 2}}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {/* <Checkbox size="sm" label="Remember me" name="persistent" /> */}
                    <Link
                      level="title-sm"
                      onClick={() => {
                        alert('010-1234-1234 로 문의 바랍니다.')
                      }}
                    >
                     비밀번호를 잊으셨나요?
                    </Link>
                  </Box>
                  <Button type="submit" fullWidth>
                    로그인
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Box>
          <Box component="footer" sx={{py: 3}}>
            <Typography level="body-xs" textAlign="center">
              © HNHN Corp 2023
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backgroundColor: 'background.level1',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)'
        })}
      />
    </CssVarsProvider>
  );
}