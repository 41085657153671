import GlobalStyles from '@mui/joy/GlobalStyles';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, {listItemButtonClasses} from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import BusinessRoundedIcon from '@mui/icons-material/Business';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {Link as RouterLink} from 'react-router-dom';
import {closeSidebar} from '../utils';
import Link from "@mui/joy/Link";
import { Dispatch, Fragment, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { apiClient } from '../lib/api/api-client';

type ApiResponseType = {
  username: string
  hospitalName?: string
  realName: string
}

function Toggler({
                   defaultExpanded = true,
                   renderToggle,
                   children,
                 }: {
  defaultExpanded?: boolean;
  children: ReactNode;
  renderToggle: (params: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
  }) => ReactNode;
}) {
  const [open, setOpen] = useState(defaultExpanded);
  return (
    <Fragment>
      {renderToggle({open, setOpen})}
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: open ? '1fr' : '0fr',
          transition: '0.2s ease',
          '& > *': {
            overflow: 'hidden',
          },
        }}
      >
        {children}
      </Box>
    </Fragment>
  );
}

export default function Sidebar() {
  const [apiResponse, setApiResponse] = useState<ApiResponseType | undefined>()

  const token = localStorage.getItem('token')
  const client = apiClient()

  useEffect(() => {
    client.POST(
      '/partner/auth/login/token/verify',
      {
        body: {
          token: token!
        }
      }
    ).then(res => {
      setApiResponse(res.data)
    })
  }, [])

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: {xs: 'fixed', md: 'sticky'},
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 10000,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
        <Typography level="title-lg">하고오개 파트너센터</Typography>
      </Box>
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >


          <ListItem nested>
            <Toggler
              renderToggle={({open, setOpen}) => (
                <ListItemButton onClick={() => setOpen(!open)}>
                  <BusinessRoundedIcon/>
                  <ListItemContent>
                    <Typography level="title-sm">간편문진</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{transform: open ? 'rotate(180deg)' : 'none'}}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{gap: 0.5}}>

              <ListItem sx={{mt: 0.5}}>
                  <ListItemButton
                    role="menuitem"
                    component={RouterLink}
                    to="/console/simple-form/answer"
                  >
                    응답지 조회
                  </ListItemButton>
                </ListItem>
                
                <ListItem sx={{mt: 0.5}}>
                  <ListItemButton
                    role="menuitem"
                    component={RouterLink}
                    to="/console/simple-form/question-url"
                  >
                    URL 확인
                  </ListItemButton>
                </ListItem>
                
              </List>
            </Toggler>
          </ListItem>


          {/* <ListItem nested>
            <Toggler
              renderToggle={({open, setOpen}) => (
                <ListItemButton onClick={() => setOpen(!open)}>
                  <BusinessRoundedIcon/>
                  <ListItemContent>
                    <Typography level="title-sm">설문</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{transform: open ? 'rotate(180deg)' : 'none'}}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{gap: 0.5}}>

                <ListItem sx={{mt: 0.5}}>
                  <ListItemButton
                    role="menuitem"
                    component={RouterLink}
                    to="/console/form/answer"
                  >
                    응답지 조회
                  </ListItemButton>
                </ListItem>
                
                <ListItem sx={{mt: 0.5}}>
                  <ListItemButton
                    role="menuitem"
                    component={RouterLink}
                    to="/console/form/set"
                  >
                    설문지 관리
                  </ListItemButton>
                </ListItem>
                
              </List>
            </Toggler>
          </ListItem> */}


          <ListItem nested>
            <Toggler
              renderToggle={({open, setOpen}) => (
                <ListItemButton onClick={() => setOpen(!open)}>
                  <BusinessRoundedIcon/>
                  <ListItemContent>
                    <Typography level="title-sm">마이페이지</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{transform: open ? 'rotate(180deg)' : 'none'}}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{gap: 0.5}}>

              <ListItem sx={{mt: 0.5}}>
                  <ListItemButton
                    role="menuitem"
                    component={RouterLink}
                    to="/console/mypage/personal-info"
                  >
                    회원정보 수정
                  </ListItemButton>
                </ListItem>
                
                {/* <ListItem sx={{mt: 0.5}}>
                  <ListItemButton
                    role="menuitem"
                    component={RouterLink}
                    to="/console/mypage/change-password"
                  >
                    비밀번호 변경
                  </ListItemButton>
                </ListItem> */}
                
              </List>
            </Toggler>
          </ListItem>


        </List>

      </Box>
      <Divider/>
      <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
        <Box sx={{minWidth: 0, flex: 1}}>
          <Typography level="title-sm">{apiResponse?.hospitalName ? `${apiResponse?.hospitalName} (${apiResponse?.realName})` : apiResponse?.realName}</Typography>
          <Typography level="body-xs">{apiResponse?.username}</Typography>
        </Box>
        <Link
          component={RouterLink}
          to="/logout"
        >
          <IconButton size="sm" variant="plain" color="neutral">
            <LogoutRoundedIcon/>
          </IconButton>
        </Link>
      </Box>
    </Sheet>
  );
}
