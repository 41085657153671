import Typography from "@mui/joy/Typography";
import SimpleFormAnswerTable from "../SimpleFormAnswerTable";
import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Link from "@mui/joy/Link";
import {Link as RouterLink, useNavigate, useParams} from "react-router-dom";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { apiClient } from "../../../../../lib/api/api-client";
import { useEffect, useState } from "react";
import { Button, Textarea } from "@mui/joy";
import dayjs from "dayjs";

type ApiResponseType = {
  id: number
  userName?: string
  userPhone?: string
  petName?: string
  petAge?: number
  petType?: "DOG" | "CAT"
  simpleFormQuestionSet?: {
    setName: string
  }
  report?: string
  finishedAt?: string
}

export default function SimpleFormAnswerItemPage() {
  const client = apiClient()
  const navigate = useNavigate()
  const [ apiResponse, setApiResponse ] = useState<ApiResponseType | undefined>();

  const { answerId } = useParams()

  useEffect(() => {
    client.GET(
      '/partner/simple-form/answer/{answerId}',
      {
        params: {
          path: {
            answerId: parseInt(answerId!)
          }
        }
      }
    ).then((res) => {
      setApiResponse(res.data)
    })
  }, [])
  
  return (
    <>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon fontSize="small"/>}
          sx={{pl: 0}}
        >
          <Link
            underline="none"
            color="neutral"
            component={RouterLink}
            to="/console"
            aria-label="Home"
          >
            <HomeRoundedIcon/>
          </Link>
          <Typography fontWeight={500} fontSize={12}>
            간편문진
          </Typography>

          <Link
            underline="none"
            color="neutral"
            component={RouterLink}
            to="/console/simple-form/answer"
          >
            <Typography fontWeight={500} fontSize={12}>
              응답지 조회
            </Typography>
          </Link>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            상세
          </Typography>

          
        </Breadcrumbs>
      </Box>

      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: {xs: 'column', sm: 'row'},
          alignItems: {xs: 'start', sm: 'center'},
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >

        <Typography level="h2" component="h1">
          응답지 상세 조회
        </Typography>

        <Box>
          <Button
            variant="outlined"
            color="neutral"
            size="sm"
            sx={{
              marginRight: "10px"
            }}
            onClick={() => {
              navigate(-1)
            }}
          >
            돌아가기
          </Button>
        </Box>

      </Box>

      <Typography>ID : {apiResponse?.id}</Typography>
      <Typography>보호자명 : {apiResponse?.userName}</Typography>
      <Typography>전화번호 : {apiResponse?.userPhone}</Typography>
      <Typography>동물명 : {apiResponse?.petName}</Typography>
      <Typography>나이 : {apiResponse?.petAge}살</Typography>
      <Typography>동물종류 : {apiResponse?.petType}</Typography>
      <Typography>응답일 : {dayjs(new Date(apiResponse?.finishedAt!).toLocaleString()).format('YYYY-MM-DD')}</Typography>
      <Typography>설문지 : {apiResponse?.simpleFormQuestionSet?.setName}</Typography>

      <Box height={20} />

      <Box>
        응답
        <Textarea value={apiResponse?.report} onClick={(ev) => (ev.target as HTMLTextAreaElement).select()} />
      </Box>
    </>
  );
}