import {Navigate, Route, Routes} from 'react-router-dom';
import ConsolePage from "./pages/ConsolePage";
import LoginPage from "./pages/LoginPage";
import LogoutPage from './pages/LogoutPage';
import SignUpPage from './pages/SignUpPage';

export default function App() {
  return (
    <Routes>
      <Route path='/' element={<Navigate to={'/console'} />}/>
      <Route path="/login" element={<LoginPage/>}/>
      <Route path="/logout" element={<LogoutPage/>}/>
      <Route path="/sign-up" element={<SignUpPage/>}/>
      <Route path="/console/*" element={<ConsolePage/>}/>
    </Routes>
  );
}