import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Link from "@mui/joy/Link";
import {Link as RouterLink} from "react-router-dom";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { Button, Checkbox, FormControl, FormLabel, Input, Stack, styled } from "@mui/joy";
import { SetStateAction, useEffect, useState } from "react";
import { apiClient } from "../../../../lib/api/api-client";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

type FormData = {
  phone: string
  email: string
  hospitalName?: string
  hospitalLogoImageUrl?: string
}


export default function PersonalInfoPage() {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<FormData>({
    phone: '',
    email: ''
  })
  const client = apiClient()

  const updateForm = async () => {
    const resp = await client.GET(
      '/partner/mypage/personal-info'
    )
    
    // TODO: 에러처리
    
    setFormData(resp.data as FormData)
  }

  const handleUploadFile = async (
    file: File, 
    filenameCallback: Function
  ) => {
    if (!file) {
      console.log('!file')
      return
    }
    const fileNameSplit = file.name.split('.')
    console.log(`filenamesplit length ${fileNameSplit}`)
    if (fileNameSplit.length == 1) {
      console.log(`filenamesplit length ${fileNameSplit.length}`)
      return
    }

    setLoading(true)

    
    const fileExt = fileNameSplit[fileNameSplit.length - 1]

    const uploadResp = await fetch(`https://cdn-upload.hago.dog?ext=${fileExt}`)
    const uploadBody = await uploadResp.json()

    const fileUrl = uploadBody.fileUrl
    const uploadUrl = uploadBody.uploadUrl

    await fetch(uploadUrl, { method: 'PUT', body: file })
    filenameCallback(fileUrl)

    setLoading(false)
  }


  useEffect(() => {
    updateForm()
  }, [])

  return (
    <>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon fontSize="small"/>}
          sx={{pl: 0}}
        >
          <Link
            underline="none"
            color="neutral"
            component={RouterLink}
            to="/console"
            aria-label="Home"
          >
            <HomeRoundedIcon/>
          </Link>
          <Typography fontWeight={500} fontSize={12}>
            마이페이지
          </Typography>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            회원정보 수정
          </Typography>
        </Breadcrumbs>
      </Box>

      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: {xs: 'column', sm: 'row'},
          alignItems: {xs: 'start', sm: 'center'},
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >

        <Typography level="h2" component="h1">
          회원정보 수정
        </Typography>

        <Box>
          <Button
            color="primary"
            size="sm"
            type='submit'
            disabled={loading}
            onClick={async () => {
              const resp = await client.PUT(
                '/partner/mypage/personal-info',
                {
                  body: formData
                }
              )
              if (resp.error) {
                alert('수정 실패')
                return
              }

              alert('수정 성공')
              
            }}
          >
            저장하기
          </Button>
        </Box>

      </Box>


      <Box>
        <form>
          <Stack gap={2} maxWidth={600}>
            <FormControl required>
              <FormLabel>휴대전화 번호</FormLabel>
              <Input 
                type="tel" name="phone" placeholder='01012341234' 
                value={formData.phone}
                onChange={ev => {
                  ev.target.value = ev.target.value.replace(/[^0-9]/g, '')
                  setFormData({
                    ...formData,
                    phone: ev.target.value
                  })
                }} 
              />
            </FormControl>
            
            <FormControl required>
              <FormLabel>이메일</FormLabel>
              <Input 
                type="email" name="email"
                value={formData.email}
                onChange={ev => {
                  setFormData({
                    ...formData,
                    email: ev.target.value
                  })
                }}
              />
            </FormControl>

            <FormControl>
              <FormLabel>병원명</FormLabel>
              <Input 
                type="string" name="hospitalName"
                value={formData.hospitalName}
                onChange={ev => {
                  setFormData({
                    ...formData,
                    hospitalName: ev.target.value
                  })
                }}
              />
            </FormControl>
            
            <FormControl>
              <FormLabel>병원 로고</FormLabel>
              <Button component="label" variant='outlined' sx={{backgroundColor: 'white'}} disabled={loading} >
                병원 로고 업로드
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={async ev => {
                    const file = ev.target.files![0]
                    handleUploadFile(file, (imageUrl: string) => {setFormData({...formData, hospitalLogoImageUrl: imageUrl})})
                  }} 
                />
              </Button>

              <Typography level='body-sm'>{formData.hospitalLogoImageUrl}</Typography>
            </FormControl>

            <Box
              component='img'
              src={formData.hospitalLogoImageUrl}
              maxWidth={300}
            />
          </Stack>
        </form>
      </Box>
    </>
  );
}