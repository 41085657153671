/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment, useEffect, useState} from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import IconButton, {iconButtonClasses} from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import SearchIcon from '@mui/icons-material/Search';
import { apiClient } from '../../../../lib/api/api-client';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { FormControl, FormLabel, Input, Link } from '@mui/joy';
import dayjs from 'dayjs';


function RowMenu() {
  return (
    <Dropdown>
      <MenuButton
        slots={{root: IconButton}}
        slotProps={{root: {variant: 'plain', color: 'neutral', size: 'sm'}}}
      >
        <MoreHorizRoundedIcon/>
      </MenuButton>
      <Menu size="sm" sx={{minWidth: 140}}>
        {/*<MenuItem>Edit</MenuItem>*/}
        {/*<MenuItem>Rename</MenuItem>*/}
        {/*<MenuItem>Move</MenuItem>*/}
        <MenuItem disabled>액션 없음</MenuItem>
        {/*<Divider />*/}
        {/*<MenuItem color="danger">Delete</MenuItem>*/}
      </Menu>
    </Dropdown>
  );
}

type RowType = {
  id: number
  userName?: string
  userPhone?: string
  petName?: string,
  petType?: "DOG" | "CAT"
  simpleFormQuestionSet?: {
    setName: string
  }
  finishedAt?: string
}


export default function SimpleFormAnswerTable() {
  const [rows, setRows] = useState<RowType[]>([])
  const [totalPage, setTotalPage] = useState(0)
  const [last, setLast] = useState(true)
  const [loading, setLoading] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState<string>()

  const client = apiClient()
  const [searchParams, setSearchParams] = useSearchParams()

  const getPage = () => {
    return parseInt(searchParams.get('page') || '1')
  }

  useEffect(() => {
    client.GET("/partner/simple-form/answer", {
      params: {
        query: {
          page: getPage() - 1,
          size: 10,
          keyword: searchParams.get('keyword') || undefined
        }
      }
    }).then(res => {
      setRows(res.data!.content as RowType[])
      setTotalPage(res.data!.totalPages!)
      setLast(res.data!.last === true)
      setLoading(false)
    })

  }, [searchParams])

  return (
    <Fragment>
      <form onSubmit={(ev) => {
        ev.preventDefault()
        if (searchKeyword) {
          setSearchParams({keyword: searchKeyword!})
        } else {
          searchParams.delete('keyword')
          setSearchParams(searchParams)
        }
      }}>
        <Input 
          size="sm" 
          placeholder="보호자명, 전화번호, 동물명으로 검색" 
          value={searchKeyword} 
          startDecorator={<SearchIcon />} 
          endDecorator={<Button type='submit'>검색</Button>}
          onChange={ev => setSearchKeyword(ev.target.value)}
        />
      </form>

      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
          <thead>
          <tr>
            <th style={{width: 60, textAlign: 'center', padding: '12px 6px'}}>
              ID
            </th>
            <th style={{width: 80, padding: '12px 6px'}}>보호자명</th>
            <th style={{width: 120, padding: '12px 6px'}}>전화번호</th>
            <th style={{width: 80, padding: '12px 6px'}}>동물명</th>
            <th style={{width: 80, padding: '12px 6px'}}>동물 종류</th>
            <th style={{width: 100, padding: '12px 6px'}}>설문지</th>
            <th style={{width: 100, padding: '12px 6px'}}>응답일</th>
            <th style={{width: 100, padding: '12px 6px'}}>결과</th>
            <th style={{width: 50, padding: '12px 6px'}}></th>
          </tr>
          </thead>
          <tbody>
          {rows.map((row) => (
            <tr key={row.id}>
              <td>
                <Typography level="body-sm" style={{textAlign: 'center'}}>{row.id}</Typography>
              </td>
              
              <td>
                <Typography level="body-sm">{row.userName}</Typography>
              </td>

              <td>
                <Typography level="body-sm">{row.userPhone}</Typography>
              </td>

              <td>
                <Typography level="body-sm">{row.petName}</Typography>
              </td>

              <td>
                <Typography level="body-sm">{row.petType}</Typography>
              </td>

              <td>
                <Typography level="body-sm">{row.simpleFormQuestionSet?.setName}</Typography>
              </td>

              <td>
                <Typography level="body-sm">{dayjs(new Date(row.finishedAt!).toLocaleString()).format('YYYY-MM-DD')}</Typography>
              </td>

              <td>
                <Typography level="body-sm">{row.finishedAt && <Link component={RouterLink} to={"./" + row.id}>응답지 조회</Link> || '미응답'}</Typography>
              </td>

              <td>
                <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
                  <RowMenu/>
                </Box>
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
      </Sheet>
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: {borderRadius: '50%'},
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon/>}
          disabled={loading || getPage() === 1}
          onClick={() => {
            setLoading(true);
            setSearchParams({...searchParams, page: (getPage() - 1).toString()})
          }}
        >
          이전 페이지
        </Button>

        <Box sx={{flex: 1}}/>
        <Box fontSize="small">
          {getPage()} / {totalPage}
        </Box>
        

        <Box sx={{flex: 1}}/>

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon/>}
          disabled={loading || last}
          onClick={() => {
            setLoading(true);
            setSearchParams({...searchParams, page: (getPage() + 1).toString()})
          }}
        >
          다음 페이지
        </Button>
      </Box>
    </Fragment>
  );
}
