import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Link from "@mui/joy/Link";
import {Link as RouterLink} from "react-router-dom";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { Button, Checkbox, FormControl, FormLabel, Input, Stack, styled } from "@mui/joy";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function ChangePasswordPage() {
  return (
    <>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Breadcrumbs
          size="sm"
          aria-label="breadcrumbs"
          separator={<ChevronRightRoundedIcon fontSize="small"/>}
          sx={{pl: 0}}
        >
          <Link
            underline="none"
            color="neutral"
            component={RouterLink}
            to="/console"
            aria-label="Home"
          >
            <HomeRoundedIcon/>
          </Link>
          <Typography fontWeight={500} fontSize={12}>
            마이페이지
          </Typography>
          <Typography color="primary" fontWeight={500} fontSize={12}>
            비밀번호 변경
          </Typography>
        </Breadcrumbs>
      </Box>

      <Box
        sx={{
          display: 'flex',
          mb: 1,
          gap: 1,
          flexDirection: {xs: 'column', sm: 'row'},
          alignItems: {xs: 'start', sm: 'center'},
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >

        <Typography level="h2" component="h1">
          비밀번호 변경
        </Typography>

        <Box>
          <Button
            color="primary"
            size="sm"
            type='submit'
          >
            저장하기
          </Button>
        </Box>

      </Box>


      <Box>
        <form>
          <Stack gap={2} maxWidth={600}>
            <FormControl required>
              <FormLabel>기존 비밀번호</FormLabel>
              <Input type="password" name="oldPassword" />
            </FormControl>
            
            <FormControl required>
              <FormLabel>새 비밀번호</FormLabel>
              <Input type="password" name="newPassword"/>
            </FormControl>

            <FormControl required>
              <FormLabel>새 비밀번호 재확인</FormLabel>
              <Input type="password" name="newPasswordConfirm"/>
            </FormControl>

          </Stack>
        </form>
      </Box>
    </>
  );
}