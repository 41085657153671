import {CssVarsProvider} from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import {Route, Routes, useNavigate} from 'react-router-dom';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import ConsoleHomePage from "./console/home/ConsoleHomePage";
import { useEffect } from 'react';
import { apiClient } from '../lib/api/api-client';
import PersonalInfoPage from './console/mypage/personal-info/PersonalInfoPage';
import ChangePasswordPage from './console/mypage/change-password/ChangePasswordPage';
import SimpleFormAnswerPage from './console/simple-form/answer/SimpleFormAnswerPage';
import SimpleFormAnswerItemPage from './console/simple-form/answer/item/SimpleFormAnswerItemPage';
import SimpleFormQuestionUrlPage from './console/simple-form/question-url/SimpleFormQuestionUrlPage';

export default function ConsolePage() {
  const navigate = useNavigate()
  const token = localStorage.getItem('token')

  useEffect(() => {
    if (!token) {
      navigate('/login')

    } else {
      apiClient().POST(
        '/partner/auth/login/token/verify',
        {
          body: {
            token: token!
          }
        }
      ).then((resp) => {
        if (resp.error) {
          navigate('/logout')
        }
      })
    }

  
  }, [navigate, token])
  
  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline/>
      <Box sx={{display: 'flex', minHeight: '100dvh'}}>
        <Header/>
        <Sidebar/>
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: {xs: 2, md: 6},
            pt: {
              xs: 'calc(12px + var(--Header-height))',
              sm: 'calc(12px + var(--Header-height))',
              md: 3,
            },
            pb: {xs: 2, sm: 2, md: 3},
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
            gap: 1,
            overflow: 'auto',
          }}
        >
          <Routes>
            <Route path="/" element={<ConsoleHomePage/>}/>
            <Route path="/simple-form/answer" element={<SimpleFormAnswerPage/>}/>
            <Route path="/simple-form/answer/:answerId" element={<SimpleFormAnswerItemPage/>}/>
            <Route path="/simple-form/question-url" element={<SimpleFormQuestionUrlPage/>}/>
            <Route path="/mypage/personal-info" element={<PersonalInfoPage/>}/>
            <Route path="/mypage/change-password" element={<ChangePasswordPage/>}/>
          </Routes>
        </Box>
      </Box>
    </CssVarsProvider>
  );
}