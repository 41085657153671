import {CssVarsProvider, styled} from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel, {formLabelClasses} from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import {useNavigate} from "react-router-dom";
import { apiClient } from '../lib/api/api-client';
import { FormEvent, SetStateAction, useEffect, useState } from 'react';
import { Checkbox, Divider, Link } from '@mui/joy';
import { kMaxLength } from 'buffer';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


type Terms = {
  termsAgeAgreed: boolean
  termsServiceAgreed: boolean
  termsReceiveAdsAgreed: boolean
}

interface FormElements extends HTMLFormControlsCollection {
  username: HTMLInputElement
  password: HTMLInputElement
  passwordConfirm: HTMLInputElement
  realName: HTMLInputElement
  hospitalName: HTMLInputElement
  phone: HTMLInputElement
  email: HTMLInputElement
  termsAgeAgreed: HTMLInputElement
  termsServiceAgreed: HTMLInputElement
  termsPrivateInfoAgreed: HTMLInputElement
  termsReceiveAdsAgreed: HTMLInputElement
}

interface SignUpFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

export default function SignUpPage() {
  const navigate = useNavigate()
  const client = apiClient()
  const [originFileNameDoc, setOriginFileNameDoc] = useState<string>()
  const [fileUrlDoc, setFileUrlDoc] = useState<string>()
  const [originFileNameLogo, setOriginFileNameLogo] = useState<string>()
  const [fileUrlLogo, setFileUrlLogo] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [hospitalOwner, setHospitalOwner] = useState<boolean>(false)

  const [terms, setTerms] = useState<Terms>({
    termsAgeAgreed: false,
    termsServiceAgreed: false,
    termsReceiveAdsAgreed: false,
  })

  const handleUploadFile = async (
    file: File, 
    setFileName: React.Dispatch<SetStateAction<string | undefined>>, 
    setFileUrl: React.Dispatch<SetStateAction<string | undefined>>
    ) => {
    if (!file) {
      console.log('!file')
      return
    }
    const fileNameSplit = file.name.split('.')
    console.log(`filenamesplit length ${fileNameSplit}`)
    if (fileNameSplit.length == 1) {
      console.log(`filenamesplit length ${fileNameSplit.length}`)
      return
    }

    setLoading(true)

    setFileName('업로드중...')
    
    const fileExt = fileNameSplit[fileNameSplit.length - 1]

    const uploadResp = await fetch(`https://cdn-upload.hago.dog?ext=${fileExt}`)
    const uploadBody = await uploadResp.json()

    const fileUrl = uploadBody.fileUrl
    const uploadUrl = uploadBody.uploadUrl

    await fetch(uploadUrl, { method: 'PUT', body: file })
    setFileUrl(fileUrl)
    setFileName(file.name)

    setLoading(false)
  }

  return (
    <CssVarsProvider defaultMode="light" disableTransitionOnChange>
      <CssBaseline/>
      <GlobalStyles
        styles={{
          ':root': {
            '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
            '--Cover-width': '50vw', // must be `vw` only
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width:
            'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(12px)',
          backgroundColor: 'rgba(255 255 255 / 0.2)'
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width:
              'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
            maxWidth: '100%',
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: 'flex',
              alignItems: 'left',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{gap: 2, display: 'flex', alignItems: 'center'}}>
              <Typography level="title-lg">하고오개 파트너센터</Typography>
            </Box>
          </Box>
          <Box
            component="main"
            sx={{
              my: 'auto',
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .${formLabelClasses.asterisk}`]: {
                visibility: 'hidden',
              },
            }}
          >
            <Stack gap={2}>
              <Typography level="h3">파트너 가입</Typography>

              <Box>
                <Typography level='body-sm'>관리자 승인 후 가입 처리됩니다.</Typography>
                <Typography level='body-sm'>가입이 승인되면 이메일과 문자로 알려드립니다.</Typography>
              </Box>
              
              <form onSubmit={async (ev: FormEvent<SignUpFormElement>) => {
                setLoading(true)
                ev.preventDefault()

                const el = ev.currentTarget.elements

                if (el.password.value !== el.passwordConfirm.value) {
                  alert('비밀번호가 재확인이 일치하지 않습니다.')
                  setLoading(false)
                  return
                }
                
                const resp = await client.POST(
                  '/partner/auth/sign-up',
                  {
                    body: {
                      username: el.username.value,
                      password: el.password.value,
                      realName: el.realName.value,
                      phone: el.phone.value,
                      email: el.email.value,
                      hospitalName: el.hospitalName.value,
                      hospitalLogoImageUrl: fileUrlLogo,
                      hospitalOwner: hospitalOwner,
                      documentImageUrl: fileUrlDoc!,
                      termsAgeAgreed: el.termsAgeAgreed.checked,
                      termsServiceAgreed: el.termsServiceAgreed.checked,
                      termsReceiveAdsAgreed: el.termsReceiveAdsAgreed.checked
                    }
                  }
                )

                if (!resp.error) {
                  alert('신청되었습니다. 가입이 승인되면 이메일과 문자로 알려드립니다.')
                  navigate('/login')
                } else {
                  alert((resp as any).error.message || '가입 신청 중 에러 발생.')
                }

                setLoading(false)
              }}>

                <Divider
                  sx={{
                    color: { xs: '#FFF', md: 'text.tertiary' },
                    '--Divider-lineColor': {
                      xs: '#FFF',
                      md: 'var(--joy-palette-divider)',
                    },
                    mt: 3
                  }}
                >
                  필수 입력
                </Divider>

                <FormControl required>
                  <FormLabel>아이디</FormLabel>
                  <Input type="string" name="username"/>
                </FormControl>
                
                <FormControl required>
                  <FormLabel>비밀번호</FormLabel>
                  <Input type="password" name="password"/>
                </FormControl>
                
                <FormControl required>
                  <FormLabel>비밀번호 재확인</FormLabel>
                  <Input type="password" name="passwordConfirm"/>
                </FormControl>
                
                <FormControl required>
                  <FormLabel>이름 (실명)</FormLabel>
                  <Input type="string" name="realName"/>
                </FormControl>
                
                <FormControl required>
                  <FormLabel>휴대전화 번호</FormLabel>
                  <Input type="tel" name="phone" placeholder='01012341234' onChange={ev => ev.target.value = ev.target.value.replace(/[^0-9]/g, '')} />
                </FormControl>
                
                <FormControl required>
                  <FormLabel>이메일</FormLabel>
                  <Input type="email" name="email"/>
                </FormControl>

                <Checkbox
                  label="원장님이신가요?" 
                  sx={{mt: 2, mb: 1}} 
                  checked={hospitalOwner}
                  onChange={ev => {
                    setHospitalOwner(ev.target.checked)
                  }}
                />

                <FormControl required>
                  <FormLabel>증빙서류 ({hospitalOwner ? '수의사 면허증 또는 사업자등록증' : '수의사 면허증 또는 학생증'})</FormLabel>
                  <Button component="label" variant='outlined' sx={{backgroundColor: 'white'}} disabled={loading}>
                    증빙서류 업로드
                    <VisuallyHiddenInput 
                      type="file" 
                      accept="image/*"
                      required
                      onChange={async ev => {
                        const file = ev.target.files![0]
                        handleUploadFile(file, setOriginFileNameDoc, setFileUrlDoc)
                      }} 
                      />
                  </Button>

                  <Typography level='body-sm'>{originFileNameDoc}</Typography>
                </FormControl>

                <Divider
                  sx={{
                    color: { xs: '#FFF', md: 'text.tertiary' },
                    '--Divider-lineColor': {
                      xs: '#FFF',
                      md: 'var(--joy-palette-divider)',
                    },
                    mt: 5
                  }}
                >
                  선택 입력
                </Divider>
                <Box>
                  <Typography level='body-sm'>병원명과 로고를 등록하시면 문진표에 노출됩니다.</Typography>
                </Box>
                <FormControl>
                  <FormLabel>병원명</FormLabel>
                  <Input type="string" name="hospitalName"/>
                </FormControl>
                
                <FormControl>
                  <FormLabel>병원 로고</FormLabel>
                  <Button component="label" variant='outlined' sx={{backgroundColor: 'white'}} disabled={loading}>
                    병원 로고 업로드
                    <VisuallyHiddenInput
                      type="file"
                      accept="image/*"
                      onChange={async ev => {
                        const file = ev.target.files![0]
                        handleUploadFile(file, setOriginFileNameLogo, setFileUrlLogo)
                      }} 
                    />
                  </Button>

                  <Typography level='body-sm'>{originFileNameLogo}</Typography>
                </FormControl>

                <Checkbox
                  label="모두 동의하기"
                  size='sm'
                  sx={{mt: 5}}
                  checked={
                    terms.termsAgeAgreed &&
                    terms.termsServiceAgreed &&
                    terms.termsReceiveAdsAgreed
                  }
                  onChange={ev => {
                    setTerms({
                      ...terms,
                      termsAgeAgreed: ev.target.checked,
                      termsServiceAgreed: ev.target.checked,
                      termsReceiveAdsAgreed: ev.target.checked
                    })
                  }}
                />

                <Divider />

                <Checkbox
                  label="(필수) 만 14세 이상입니다."
                  size='sm'
                  name='termsAgeAgreed'
                  checked={terms.termsAgeAgreed}
                  onChange={ev => setTerms({...terms, termsAgeAgreed: ev.target.checked})}
                  required 
                />

                <Checkbox
                  label={
                    <div>
                      <span>(필수) 서비스 이용약관 에 동의합니다. </span>
                      <Link href='https://sites.google.com/view/hago-terms' target="_blank" rel="noopener" sx={{zIndex: 2}}>보기</Link>
                    </div>
                  } 
                  size='sm'
                  name='termsServiceAgreed'
                  checked={terms.termsServiceAgreed}
                  onChange={ev => setTerms({...terms, termsServiceAgreed: ev.target.checked})}
                  required />

                <Checkbox
                  label="(선택) 광고성 정보 수신 동의" 
                  size='sm'
                  name='termsReceiveAdsAgreed'
                  checked={terms.termsReceiveAdsAgreed}
                  onChange={ev => setTerms({...terms, termsReceiveAdsAgreed: ev.target.checked})}
                />

                <Button type="submit" fullWidth sx={{mt: 3}} disabled={loading}>
                  신청하기
                </Button>
              </form>
            </Stack>

          </Box>
          <Box component="footer" sx={{py: 3}}>
            <Typography level="body-xs" textAlign="center">
              © HNHN Corp 2023
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backgroundColor: 'background.level1',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)'
        })}
      />
    </CssVarsProvider>
  );
}